const handleClick = (event) => {
    const target = event.target;
    if (
      target.tagName === 'A' &&
      target.hostname !== window.location.hostname
    ) {
      target.setAttribute('target', '_blank');
      target.setAttribute('rel', 'noopener noreferrer');
    }
  };
  
  export const onRouteUpdate = () => {
    document.addEventListener('click', handleClick);
  };
  
  export const onPreRouteUpdate = () => {
    document.removeEventListener('click', handleClick);
  };
  